/*
=============== 
Navbar
===============
*/
nav {
    background: var(--clr-white);
    box-shadow: var(--light-shadow);
    position: sticky;
    top: 0;
    height: 76px;
    z-index: 2;
}

.nav-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
}

.nav-toggle {
    font-size: 1.5rem;
    color: var(--color-yellow-light);
    background: transparent;
    
    border-color: transparent;
    transition: var(--transition);
    cursor: pointer;
}

.nav-toggle:hover {
    color: var(--color-yellow-dark);
    /* transform: rotate(90deg); */
}

.logo {
    height: 40px;
}

.img-logo {
    height: 40px;
    color: var(--color-yellow-dark);
    font-size: 2rem;
    margin: 0;
}

.img-logo a {
    color: inherit;
    color: #D78D1D;
}

.text-logo {
    font-size: 1.5rem;
    padding-left: 0.8rem;
    margin: 0;
}

.text-logo span {
    color: var(--clr-red-dark);
    color: #D78D1D;
    font-size: 1.7rem;
    font-weight: 600;

}
.links {
background-color: var(--clr-white);
width: 100%;


}

.links a {
    color: var(--clr-grey-3);
    color: #D78D1D;
    font-weight: 500;
    font-size: 1rem;
    text-transform: capitalize;
    letter-spacing: var(--spacing);
    display: block;
    padding: 0.5rem 1rem;
    /* transition: var(--transition); */
    transition: all 0.3s linear;

}

ul{
    list-style-type: none;
}

.links li a:hover {
    /* background: var(--clr-grey-9); */
    color: var(--clr-grey-4);
    color: #905E14;
    /* padding-left: 1.3rem; */
    /* font-weight: 500; */
    text-decoration: underline;
    text-decoration-color: var(--clr-grey-4);
    text-decoration-color: #905E14;
    text-decoration-thickness: 2px;
    text-underline-offset: 4px;
}

.social-icons {
    display: none;
}

.links-container {
    height: 0;
    overflow: hidden;
    transition: var(--transition);
    display: flex;
    justify-content: center;
    align-items: center;
}

.show-container {
    height: 10rem;
}



@media screen and (min-width: 800px) {
    .nav-center {
        width: 95%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.5rem;
    }

    .nav-header {
        padding: 0;
    }

    .nav-toggle {
        display: none;
    }
    .links {
        background-color: transparent;
    }
    .links li{
        height: fit-content;
    }

    .links-container {
        height: auto !important;
        
    }


    .links {
        display: flex;
        justify-content: center;
        align-items: center;
        
    }

    .links a {
        padding: 0;
        margin: 0 1.5rem;
    }

    .links a:hover {
        padding: 0;
        background: transparent;
        
    }

    .social-icons {
        display: flex;
    }

    .social-icons a {
        margin: 0 0.5rem;
        color: var(--clr-primary-5);
        transition: var(--transition);
    }

    .social-icons a:hover {
        color: var(--clr-primary-7);
    }
}

@media screen and (max-width: 1000px) {
    .links a {
        padding: 0;
        margin: 0 0.3rem;
        font-size: 0.8rem;
    }
}