.projects{
    width: 90%;
    margin: auto;
}

.projects-container{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    flex-wrap: wrap;
}