.singleProjectCard{
    width: 300px;
    height: fit-content;
    border-radius: 20px;
    overflow: hidden;
    background-color: rgb(255, 255, 255);
    cursor: pointer;
}

.singleProjectCard .img-container{
    width: 100%;
    height: 50%;
}

.singleProjectCard .img-container img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.singleProjectCard-container h3 {
    color: var(--color-yellow-dark);
    letter-spacing: 2px;
    font-family: var(--font-heading);
    font-size: 1rem;
    font-weight: 500;
    line-height: 3rem;
    margin-bottom: 0.2rem;
}

.singleProjectCard-container h4 {
    color: var(--clr-red-dark);
    font-family: var(--font-banner-1);
    font-size: 2rem;
    font-weight: bold;
    letter-spacing: 6px;
    margin-bottom: 1rem;
}

.singleProjectCard-container p {
    color: black;
    line-height: 1.5rem;
    margin: 0;
}

.singleProjectCard-technology{
    display: flex;
    justify-content: center;
    align-items: center;
}

.singleProjectCard-technology p{
    padding: 1px 5px;
    border-radius: 5px;
    margin: 5px;
    font-size: 0.8rem;
    background-color: lightgrey;
}