.cvCard{
    width: 30%;
    height: 100px;
    background-image: url("https://images.pexels.com/photos/590016/pexels-photo-590016.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1");
    object-fit: cover;
    background-size: 100%;
    background-position: center;
    border-radius: 20px;
    position: relative;
    cursor: pointer;
    
}

.cvCard .cvCardTitle{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    
}

.cvCard .cvCardTitle div{
    width: 250px;
    padding: 10px 5px;
    background-color: rgba(255, 255, 255, 0.762);
    color: var(--color-yellow-light);
    font-weight: bold;
    letter-spacing: 2px;
    text-transform: uppercase;
    
}

.cvCard .cvCardLayer{
    display: none;
    width: 100%;
    height: 100%;
    background-color: rgba(211, 211, 211, 0.796);
    position: absolute;
    border-radius: 20px;
    top: 0;
    left: 0;
    z-index: 2;
}

.cvCard:hover .cvCardTitle{
    display: none;
    
}

.cvCard:hover .cvCardLayer{    
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.cvCard:hover .cvCardLayer div{
    width: 250px;
    padding: 10px 5px;
    background-color: white;
    padding: 10px 5px;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 2px;
    color: rgb(99, 99, 99);
    
}

.cvCard:hover .cvCardLayer div a {
    color: inherit;
}