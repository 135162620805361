.hero {
    width: 100%;
    height: calc(100vh - 76px);
    /* display: flex;
    justify-content: center;
    align-items: center; */
    overflow: hidden;
    transition: all 2s linear;
}

.hero-content {
    width: 200vw;
    height: 100%;
    display: flex;

}

.itemHero {
    width: 100vw;
    height: 100%;
    position: relative;

}

.itemHero img {
    width: 100vw;
    height: 100%;
    object-fit: cover;
}

.itemText {
    position: absolute;
    width: fit-content;
    height: fit-content;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    min-height: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 0.8;
    border-radius: 10px;
    z-index: 2;
}

.itemText h3 {
    color: var(--clr-grey-9);
    color: var(--color-yellow-light);
    font-family: var(--font-banner-1);
    font-size: 5rem;
    letter-spacing: 9px;
    font-weight: 200;
    text-transform: none;
    margin-bottom: 5rem;
    text-align: center;
}

.itemText button {
    padding: 1rem 2rem;
    cursor: pointer;
    font-family: var(--font-banner-2);
    letter-spacing: 3px;
    /* background-color: var(--clr-grey-9); */
    background-color: transparent;
    border: solid 2px var(--color-yellow-light);
    /* border: solid 1px var(--clr-grey-3); */
    color: white;
    font-weight: 400;
    font-size: 1rem;
    text-transform: uppercase;
    border-radius: 10px;
    transition: var(--transition);
}

.itemText a {
    color: inherit;
}

.itemText button:hover {
    /* background-color: var(--clr-grey-8); */
    background-color: var(--color-yellow-dark);
    /* color: var(--clr-grey-1); */
    border-color: var(--color-yellow-dark);
    transition: var(--transition);
}

.layerHero {
    width: 100%;
    height: 100%;
    background-color: rgba(15, 10, 2, 0.6);
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
}

@media screen and (max-width: 760px) {
    .itemText h3 {
        font-size: 1.8rem;
    }

    .itemText button {
        font-size: 0.8rem;
    }
}