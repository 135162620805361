.about{
    width: 90%;    
    margin: 2rem auto;
    padding: 0.5px 5px 5px 5px;
    background-color: white;
    border-radius: 20px;
    /* overflow: hidden; */
}

.about-container{
    width: 90%;  
    /* min-width: 900px; */
    margin: auto;
    display: flex;  
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
}

.about-container-left{
    flex: 2;
    min-width: 550px;
    /* min-width: 600px; */
}

.about-container-left ul{
    width: 100%;
    
    text-align: left;
    list-style: none;
    
}

.about-container-left ul li{
    padding: 5px;
    margin: 1rem;
    font-size: 1.1rem;
}

.about-container-left ul li:before {
    content: "\1985";
    color: var(--color-yellow-light);
    font-weight: bold;
    display: inline-block;
    width: 1rem;
    height: 1rem;
    margin-left: -1rem;
   
}

.about-container-left ul li span{
    position: relative;
    left: 15px;
}

.about-skills{
    display: flex;
    flex-wrap: wrap;
}
.about-skills span{
    border-radius: 10px;
    margin: 5px;
    padding: 5px 10px;
    background-color: lightgrey;
    letter-spacing: 1px;
}


.about-container-right{
    flex: 1;
    min-width: 400px;
    display: flex;
    flex-direction: column;    
    text-align: left;
    
}

.about-container-right > div{
    background-color: var(--color-yellow-lighter);
    margin: 5px;
    padding: 15px;
    border-radius: 10px;
}

.about-container-right h4{
    text-align: center;
    color: rgb(63, 63, 63);
}
.about-container-right p{
    color: rgb(63, 63, 63);
    font-weight: 600;
    letter-spacing: 1px;
}

.about-right-item{
    display: flex;
    gap: 10px;
}
.about-technology{
    flex: 1;
    
}

.about-icons{
    
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
}

.about-icons img{
    width: 30px;
    height: 30px;
}

@media screen and (max-width:992px) {
    .about-container{
        flex-wrap: wrap;
        width: 90%;
        min-width: 400px;
       
    }

    .about-container-left{
        width: 80%;
        min-width: 400px;
       
    }

    .about-container-left ul{
        width: 80%;
    }

    .about-skills{
        width: 80%;
    }

    .about-container-right{
        min-width: 400px;
    }

    .about-container-right>div{
        max-width: 400px;
    }

    .about-container-right-item{
        min-width: 200px;
    }

    .about-icons{
        min-width: 200px;
    }
}

@media screen and (max-width:480px) {
    .about-container {
        flex-wrap: wrap;
        width: 90%;
        min-width: 300px;

    }

    .about-container-left {
        width: 80%;
        min-width: 300px;

    }

    .about-container-left ul {
        width: 80%;
    }

    .about-skills {
        width: 80%;
    }

    .about-container-right {
        min-width: 300px;
    }

    .about-container-right>div {
        max-width: 250px;
    }

    .about-container-right-item {
        min-width: 200px;
    }

    .about-icons {
        min-width: 100px;
    }
}