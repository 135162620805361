@import url('https://fonts.googleapis.com/css2?family=Libre+Franklin:wght@200&family=WindSong&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Grey+Qo&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Overlock+SC&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Itim&display=swap');
/*
=============== 
Variables
===============
*/

:root {
  /* dark shades of primary color*/
  --clr-primary-1: hsl(205, 86%, 17%);
  --clr-primary-2: hsl(205, 77%, 27%);
  --clr-primary-3: hsl(205, 72%, 37%);
  --clr-primary-4: hsl(205, 63%, 48%);
  /* primary/main color */
  --clr-primary-5: hsl(205, 78%, 60%);
  /* lighter shades of primary color */
  --clr-primary-6: hsl(205, 89%, 70%);
  --clr-primary-7: hsl(205, 90%, 76%);
  --clr-primary-8: hsl(205, 86%, 81%);
  --clr-primary-9: hsl(205, 90%, 88%);
  --clr-primary-10: hsl(205, 100%, 96%);
  /* darkest grey - used for headings */
  --clr-grey-1: hsl(209, 61%, 16%);
  --clr-grey-2: hsl(211, 39%, 23%);
  --clr-grey-3: hsl(209, 34%, 30%);
  --clr-grey-4: hsl(209, 28%, 39%);
  /* grey used for paragraphs */
  --clr-grey-5: hsl(210, 22%, 49%);
  --clr-grey-6: hsl(209, 23%, 60%);
  --clr-grey-7: hsl(211, 27%, 70%);
  --clr-grey-8: hsl(210, 31%, 80%);
  --clr-grey-9: hsl(212, 33%, 89%);
  --clr-grey-10: hsl(210, 36%, 96%);
  --clr-white: #fff;
  --clr-red-dark: hsl(360, 67%, 44%);
  --clr-red-light: hsl(360, 71%, 66%);
  --clr-green-dark: hsl(125, 67%, 44%);
  --clr-green-light: hsl(125, 71%, 66%);
  --clr-black: #222;
  --transition: all 0.6s linear;
  --spacing: 0.1rem;
  --radius: 0.25rem;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  --max-width: 1170px;
  --fixed-width: 620px;

  --font-motto: 'WindSong', cursive;


  --font-banner-1: 'Grey Qo', cursive;
  --font-banner-2: 'Overlock SC', cursive;
  --color-yellow-light: #d78d1d;
  --color-yellow-lighter: rgb(230, 193, 136);
  --color-yellow-dark: #905e14;

  /* --font-heading: 'Overlock SC', cursive; */
  --font-heading: 'Itim', cursive;
}

/*
=============== 
Global Styles
===============
*/

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  background: var(--clr-grey-10);
  color: black;
  line-height: 1.5;
  font-size: 0.875rem;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}

h1,
h2,
h3,
h4 {
  letter-spacing: var(--spacing);
  text-transform: capitalize;
  line-height: 1.25;
  margin-bottom: 0.75rem;
}

h1 {
  font-size: 3rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.25rem;
}

h4 {
  font-size: 0.875rem;
}



.heading-text {
  text-align: center;
  color: var(--color-yellow-light);
  font-family: var(--font-heading);
  text-transform: uppercase;
}

.underline {
  width: 100px;
  height: 3px;
  /* background-color:var(--clr-grey-4) ; */
  background-color: var(--color-yellow-light);
  font-family: var();
  margin: auto;
}

.section__padding {
  padding: 4rem 6rem;
}

.section__margin {
  margin-top: 4rem;
}

@media screen and (min-width: 800px) {
  h1 {
    font-size: 4rem;
  }

  h2 {
    font-size: 2.5rem;
  }

  h3 {
    font-size: 1.75rem;
  }

  h4 {
    font-size: 1rem;
  }

  body {
    font-size: 1rem;
  }

  h1,
  h2,
  h3,
  h4 {
    line-height: 1;
  }
}

/*  global classes */

/* section */
.section {
  width: 90vw;
  margin: 0 auto;
  max-width: var(--max-width);
}

@media screen and (min-width: 992px) {
  .section {
    width: 95vw;
  }
}

@media screen and (max-width: 1050px) {
  .section__padding {
    padding: 2rem;
  }

  .section__margin {
    /* margin: 2rem; */
  }
}

@media screen and (max-width: 800px) {
  .section__padding {
    /* padding: 0.5rem; */
  }

  .section__margin {
    /* margin: 0.5rem; */
  }
}

@media screen and (max-width: 500px) {
  .section {
    width: 100vw;
  }
}