.contact {
    min-height: 150px;
    /* background: url("../../assets/home/blue-flowers.jpg"); */
    background-color: var(--color-yellow-dark);
    /* color: black; */
    color: white;


}

.contact-container {
    padding: 1rem 2rem;
    /* background-color: rgba(255,255,255, 0.5); */
}

.contact-container p {
    /* color: var(--clr-primary-1); */
    color: white;
    line-height: 1rem;
}

.contact-container h2 {
    font-family: var(--font-heading);
    letter-spacing: 3px;
    font-weight: 400;
    margin-bottom: 2rem;
}

.author {
    font-size: 0.8rem;
    text-align: center;
    margin-bottom: 0;
}

.author a {
    color: white;
    font-weight: 600;
    transition: var(--transition);
}

.author a:hover {
    color: wheat;
    transition: var(--transition);
}


.contact-underline {
    width: 100px;
    height: 5px;
    background-color: var(--clr-grey-4);
}

.icon {
    font-size: 1.6rem;
}

.contact-container-left{
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 20px;
}

.contact-info {
    display: flex;
    align-items: center;
}

.contact-info a{
    color: inherit;
}

.contact-icons-social{
    color: white;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;

}
.contact-icons-social a{
    color: inherit;
}

@media screen and (max-width: 450px) {
    .contact-container p {
        line-height: 0.7rem;
    }

    .contact-container h2 {
        font-size: 1.5rem;
        letter-spacing: 2px;
        font-weight: 400;
        margin-bottom: 1rem;
    }

    .author {
        font-size: 0.5rem;
        text-align: center;
        margin-bottom: 0;
    }


    .contact-info span {
        font-size: 0.7rem;
    }

    .icon {
        font-size: 1rem;
    }
}