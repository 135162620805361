.languageSelector{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 10px;
    
}

.languageSelector .selector{
    width: auto;
    height: 40px;
    background-color: bisque;
    display: block;
        font-family: inherit;
        font-size: 100%;
        margin: 0 auto;
        box-sizing: border-box;
       border: none;
        padding: 5px;
        
        box-shadow: inset 1px 1px 3px #ccc;
            border-radius: 5px;
}